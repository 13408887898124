<template>
  <echarts-com
    v-loading="loading"
    id="parkingDensity-parkingSpaceType"
    :options="options"
  />
</template>

<script>
import { formatRatio } from "@/utils/util";

export default {
  components: {
    echartsCom: () => import("@/components/echartsCom"),
  },
  data() {
    return {
      options: {},
      loading: false,
    };
  },
  methods: {
    async getOptions(params = {}) {
      try {
        this.loading = true;
        let res = await this.$http.post(
          "/statistics/parking/type/count",
          params
        );
        if (res.code === 0) {
          if (res.data) {
            // let data = res.data.infoList.map(item=>{
            //   return {
            //     value: item.count,
            //     percentage: formatRatio(item.percentage) + "%",
            //     name: item.type||""
            //   }
            // })
            const data = [
              { value: "50", percentage: "50%", name: "年租车位" },
              { value: "20", percentage: "20%", name: "月租车位" },
              { value: "20", percentage: "20%", name: "临时车位" },
              { value: "10", percentage: "10%", name: "当次免费停车" },
            ];

            this.$set(this, "options", {
              tooltip: {
                trigger: "item",
              },
              legend: {
                type: "scroll",
                icon: "circle",
                orient: "vertical",
                right: 0,
                top: "center",
                itemWidth: 10,
                itemHeight: 10,
                formatter(labelName) {
                  let item = data.find((item) => item.name === labelName);
                  return `{name|${item.name}}{dividingLine||}{percentage|${item.percentage}}`;
                },
              },
              textStyle: {
                rich: {
                  name: {
                    color: "white",
                    width: 70,
                  },
                  dividingLine: {
                    color: "#eee",
                    width: 10,
                    align: "right",
                  },
                  percentage: {
                    color: "white",
                    width: 55,
                    align: "right",
                  },
                },
              },
              series: [
                {
                  type: "pie",
                  radius: ["67%", "90%"],
                  left: 0,
                  right: "50%",
                  top: 0,
                  bottom: 0,
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderWidth: 100,
                  },
                  label: {
                    show: true,
                    position: "center",
                    color: "white",
                    formatter: (params) => {
                      return `{total|100}{unit|个}\n\r{active|总车位}`;
                    },
                    rich: {
                      unit: {
                        fontSize: 10,
                        color: "white",
                      },
                      total: {
                        fontSize: 24,
                        color: "white",
                      },
                      active: {
                        fontSize: 16,
                        color: "white",
                      },
                    },
                  },
                  data,
                },
              ],
            });
          } else {
            this.$set(this, "options", {});
            this.list = [];
          }
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("车位类型统计获取失败");
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
